require('../css/login/login.scss');
import $ from 'jquery';

import React from 'react';
import {render} from 'react-dom';
import ErrorMessageBox from '@App/js/inputForm/errorMessageBox';

var errors={general:0,
			email:0,
			pass:0};
var errorBox=document.getElementById('ErrorMessageBox');

var redirectString=$('#RedirectString').html();
console.log(redirectString);
$(document).ready(function(){
	$('#emailInput').focus();
	$('#submitLogin').click(function(){
		login($('#emailInput').val(),$('#passwordInput').val());
	});
	$('#passwordInput').on('keypress',function(e) {
		if(e.which == 13)
			login($('#emailInput').val(),$('#passwordInput').val());
	});
	checkRedirect();
});

function loginRequest(email,password){
	let success=false;
	$.ajax({
		url:'/account/login',
		data:{email:email,password:password},
		method:'POST',
		success:function(data){
			
			let userObject=JSON.parse(data);
			//console.log(userObject);


			if(data==0){
				//set General error to user or pass wrong
				errors.general=1;

			}else if(userObject.username!==undefined){
				//console.log(redirectString);
				success=true;
				if(redirectString===undefined||redirectString.includes('signup'))
				 	window.location='/';
				else
					window.location=redirectString;

			}else{
				//set General error server serror
				errors.general=3;
				
			}
		},
		complete:function(){
			if(!success){
				refreshError();
				enableSubmitButton();
			}
		}
	});
}

function login(email,password){
	enableSubmitButton(false);
	let check=true;
	errors.general=0;
	errors.email=0;
	errors.pass=0;
	if(inputCheck(email,'e')>0)
		check=false;
	if(inputCheck(password,'p')>0)
		check=false;
		
	refreshError();
	if(check)
		loginRequest(email,password);
	else
		enableSubmitButton();
	
}

function inputCheck(input,type){
	let error=0;
	if(input.length<1){
		error=1;
	}else if(type==='e'){
		if(!(/^(?:[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/).test(input))
			error=2
	}else if(type==='p'){
		if(!(/^[\w!@#$%^&*()]{1,}$/).test(input))
			error=3
	}

	if(type==='u')
		errors.email=error;
	if(type==='p')
		errors.pass=error;

	return error;
}

function enableSubmitButton(enable=true){
	if(enable){
		$('#submitLogin').prop('disabled', false);
		$('#submitLogin').val('Login');
	}else{
		$('#submitLogin').prop('disabled', true);
		$('#submitLogin').val('Loggin in...');
	}
}

function refreshError(){
	render(<ErrorMessageBox type='login' general={errors.general} email={errors.email} pass={errors.pass}/>,errorBox);
}

function checkRedirect(){
	if(redirectString==='/upload')
		errors.general=201;
	if(redirectString==='/settings')
		errors.general=202;
	// else if(redirectString!=='/')
	// 	errors.general=2;
	refreshError();
}